import React, {useState} from 'react'
import {colours} from "../styles/colours";
import axios from "axios";
import {navigate} from "gatsby";

const pageStyles = {
    width: "100%",
    height: "100%",
    margin: 0,
    padding: 0
}

const backgroundStyle = {
    backgroundColor: colours.yellow,
    paddingTop: "20px",
    width: "100vw",
    height: "100vh",
}

const mainContentStyle = {
    maxWidth: "500px",
    height: "100vh",
    margin: "auto",
    display: "flex",
    flexDirection: "column",
    position: "relative"
}

const cashOrderHeaderStyle = {
    marginTop: "100px",
    textAlign: "center",
    width: "100%",
    margin: 0,
    fontSize: "3em",
    color: colours.red,
}

const cashOrderMessageStyle = {
    textAlign: "center",
    width: "100%",
    margin: 0
}

const formText = {
    margin: "auto",
    textAlign: "center"
}

const formInput = {
    width: "100%",
    margin: "0 auto 10px"
}

const buttonDiv = {
    position: "relative",
    margin: "20px auto",
    width: "100%",
    height: "5em"
}

const checkoutButton = {
    backgroundColor: colours.red,
    padding: "2% 5%",
    width: "100%",
    height: "2.5em",
    margin: "auto"
}

const checkoutText = {
    margin: "auto",
    color: colours.yellow,
    fontSize: "x-large",
    fontWeight: "bold",
}


const CashOrderPage = ({location}) => {
    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [addressLine1, setAddressLine1] = useState("")
    const [addressLine2, setAddressLine2] = useState("")
    const [townOrCity, setTownOrCity] = useState("")
    const [postcode, setPostcode] = useState("")

    const handleClick = async (event) => {
        const loaderDiv = document.getElementById("loader")
        loaderDiv.classList.add("loader")

        const eventBody = {
            ...location.state,
            name,
            email,
            addressLine1,
            addressLine2,
            townOrCity,
            postcode
        }
        try {
            await axios.post("https://x9ot4cac78.execute-api.eu-west-2.amazonaws.com/Prod/paymentReceivedNoStripe", eventBody)
        } catch (e) {
            loaderDiv.classList.remove("loader")
            const errorMessage = document.getElementById("errorMessage")
            errorMessage.innerHTML = "Something went wrong in the background :( Message Ethan for help"
            return
        }
        const errorMessage = document.getElementById("errorMessage")
        errorMessage.innerHTML = `All successful, check Clubhouse for full notes for name ${name}`

        return await navigate(-1)
    }

    return (
        <main style={pageStyles}>
            <title>Cash Order</title>
            <div style={backgroundStyle}>
                <div style={mainContentStyle}>
                    <h1 style={cashOrderHeaderStyle}>Cash Order</h1>
                    <h3 style={cashOrderMessageStyle}>Fill in the details below</h3>
                    <form style={{margin: "20px auto"}} onSubmit={(e) => e.preventDefault()}>
                        <p style={formText}>Name</p>
                        <input type={"text"} style={formInput} onChange={(e) => setName(e.target.value)}/>
                        <p style={formText}>Email</p>
                        <input type={"email"} style={formInput} onChange={(e) => setEmail(e.target.value)}/>
                        {   location.state !== undefined ?
                                location.state.deliver ? (<>
                                        <p style={formText}>Address Line 1</p>
                                        <input type={"text"} style={formInput} onChange={(e) => setAddressLine1(e.target.value)}/>
                                        <p style={formText}>Address Line 2</p>
                                        <input type={"text"} style={formInput} onChange={(e) => setAddressLine2(e.target.value)}/>
                                        <p style={formText}>Town or City</p>
                                        <input type={"text"} style={formInput} onChange={(e) => setTownOrCity(e.target.value)}/>
                                        <p style={formText}>Postcode</p>
                                        <input type={"text"} style={formInput} onChange={(e) => setPostcode(e.target.value)}/>
                                    </>
                                ) : (
                                    <p style={formText}>Collection Chosen</p>
                                ) : <p/>
                        }
                        <div style={buttonDiv}>
                            <button style={checkoutButton} role="link" onClick={handleClick}>
                                <div id={"loader"} style={{margin: "0 auto 200%"}}>
                                    <h3 style={checkoutText} className={"checkoutText"}>Submit</h3>
                                </div>
                            </button>
                        </div>
                    </form>
                    <p id={"errorMessage"}/>
                </div>
            </div>
        </main>
    )
}

export default CashOrderPage
